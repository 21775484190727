.grid-controls {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 1rem;

  .search {
    flex-grow: 1;
  }

  .buttons {
    flex-grow: 0;
  }
}
