// Theme colors
$tls-primary: #285aff;
$tls-primary-dark: #1746e4;
$tls-secondary: #ed0000;
$tls-tertiary: #73b355;

// Other TLS brand colors
$tls-orange: #f59600;
$tls-orange-dark: #db7e04;
$tls-orange-dark-hover: #c96f00;
$tls-green: #86bb3b;
$tls-blue: #008def;
$tls-blue-dark: #285aff;
$tls-purple: #8433d0;
$tls-red-dark: #c90000;

$dark-primary-text: #343a40;
$light-primary-text: white;
$light-grey-text: #a2a2a2;
$grey-natural: #6c757d;

// Theming
$light-background: #fafafa;
$dark-background: #2c2c2c;

$ms-color-gray190: #201f1e !default;
$ms-color-gray160: #323130 !default;
$ms-color-gray20: #f3f2f1 !default;

.color-dark-primary-text {
  color: $dark-primary-text;
}
